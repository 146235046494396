import Divider from 'components/atoms/divider'
import Link from 'next/link';
import { getPathForAirport } from 'utils/routing'

const Footer = ({ footerLinks, footerParkingLinks, facebookLink, twitterLink, instagramLink }) => {
    return (
        <footer className="text-blue-500">
            <div className="max-w-7xl mx-auto px-4">
                <div className="py-10 flex flex-col sm:flex-row gap-10 flex-wrap">
                    {(footerParkingLinks||[]).map((menuGroup) => (
                        <div key={menuGroup.id}>
                            <h2 className="text-blue-500 text font-bold">{menuGroup.title}</h2>
                            <ul className="mt-6 flex flex-col gap-2">
                                {(menuGroup.items||[]).map(menuItem => (
                                    <li className="font-light" key={menuItem.id}>
                                        <Link href={menuItem.related?.__contentType === "api::airport.airport" ? getPathForAirport(menuItem.related.slug) : menuItem.path}>
                                            {menuItem.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <Divider />
                <div className="py-10 flex flex-col lg:flex-row lg:items-center gap-x-4 gap-y-8">
                    <p className="block m-0">
                        <span className="font-extrabold">Cheap Airport Parking</span>{" "}
                        <br className="hidden lg:block xl:hidden" />
                        <span className="whitespace-nowrap">owned by Yellow Lilo Ltd © {new Date().getFullYear()}</span>
                    </p>
                    <nav className="flex-1 w-full flex flex-col sm:flex-row sm:items-center lg:justify-evenly gap-4 flex-wrap">
                        {(footerLinks||[]).map((menuItem) => (
                            <Link key={menuItem.id} href={menuItem.path}>
                                <a className="flex-1 block">
                                {menuItem.title}
                                </a>
                            </Link>
                        ))}
                        <a href="https://www.iubenda.com/privacy-policy/32201238" className="flex-1 block no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda-nostyle" title="Privacy Policy ">Privacy Policy</a>
                        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
                            (function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                        ` }}></script>
                        <a href="https://www.iubenda.com/privacy-policy/32201238/cookie-policy" className="flex-1 block no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda-nostyle" title="Cookie Policy ">Cookie Policy</a>
                        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
                            (function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                        ` }}></script>
                        <a href="https://www.iubenda.com/terms-and-conditions/32201238" className="flex-1 block no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iubenda-nostyle" title="Terms and Conditions ">Terms and Conditions</a>
                        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
                            (function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                        ` }}></script>
                    </nav>
                    <div className="flex flex-row items-center gap-4">
                        <a key="facebook" href={facebookLink || "#"} className="rounded-xl h-10 w-10 bg-pink-100 flex items-center justify-center" aria-label="Facebook">
                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                        </a>
                        {twitterLink && (
                            <a key="twitter" href={twitterLink || "#"} className="rounded-xl h-10 w-10 bg-pink-100 flex items-center justify-center" aria-label="Twitter">
                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
                            </a>
                        )}
                        <a key="instagram" href={instagramLink || "#"} className="rounded-xl h-10 w-10 bg-pink-100 flex items-center justify-center" aria-label="Instagram">
                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
